import React, { Component } from 'react';
import '../styles/siteLayout.css';
// import SiteContainer from '../container/site/SiteContainer';
import Sidebar from '../../sidebar/index/Container';
import SiteContent from './SiteContent';
import Footer from '../../footer/Footer';
import { SidebarProvider } from '../../sidebar/provider/SidebarProvider';

class SiteLayout extends Component {
    constructor(props) {
        super(props);
        this.shouldContinue = true;
        this.handdleToggleSidebar = this.handdleToggleSidebar.bind(this);
        this.shouldSideBarClose = this.shouldSideBarClose.bind(this);
        this.state = {
            isSidebarOpen: false,
            isMobil: false,
            avtiveId: "L01"
        };
    }
    //#region   LIFE CYCLES    ===========================================    
    componentDidMount = () => {
        this.shouldContinue = true;
        if (window.innerWidth < 768) {
            // console.log('small screen = ', ?window.innerWidth)
            this.setGenericSetState({ isMobil: true })
        } else {
            this.setGenericSetState({ isSidebarOpen: true })
        }
    }
    componentWillUnmount = () => {
        this.shouldContinue = false;
    }
    //#endregion
    //#region   DEFAULT    ===============================================
    setGenericSetState = async (setStateValue) => {
        this.shouldContinue && this.setState(() => {
            return setStateValue
        });
    }
    //#endregion

    handdleToggleSidebar = () => {
        // console.log(this.state.isSidebarOpen)
        this.shouldContinue && this.setState((prevState) => {
            return { isSidebarOpen: !prevState.isSidebarOpen }
        })
    }
    shouldSideBarClose = () => {
        // console.log("check to close")
        if (this.state.isMobil) {
            return this.handdleToggleSidebar()
        }
    }
    render() {
        const { isSidebarOpen } = this.state;
        return (
            <div className={this.props.siteBgBodyColor}>
                <SidebarProvider>
                    <div >
                        {isSidebarOpen
                            &&
                            <Sidebar
                                isSidebarOpen={isSidebarOpen}
                                handdleToggleSidebar={this.handdleToggleSidebar}
                                shouldSideBarClose={this.shouldSideBarClose}
                                activeId={this.state.avtiveId}
                            />
                        }
                        <SiteContent
                            isSidebarOpen={isSidebarOpen}
                            handdleToggleSidebar={this.handdleToggleSidebar}
                        >
                            {this.props.children}
                        </SiteContent>
                    </div>
                </SidebarProvider>
                <Footer
                    isSidebarOpen={isSidebarOpen}
                />
            </div>

        );
    }
}

export default SiteLayout;

// <React.StrictMode>
// </React.StrictMode>

