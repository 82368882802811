import React, { Component } from "react";
import { Link } from 'react-router-dom';
import SmallHeader from '../../../components/a-h/header/SmallHeader';
import BridgeToNowere from "./image/BridgeToNowere";
// import BodyContainer from 'components/container/a-h/BodyContainer';
import './styles/style.css';
import Page from "../../../components/i-p/page/Page";

export default class PageNotFound extends Component {

    render() {
        return (
            <React.Fragment>
                <SmallHeader
                    title="Page Not Found - 404"
                    className={this.props.siteBgColor}
                />
                <Page className="text-center flex-vertical position-relative page-not-found-bg">
                    <BridgeToNowere />
                    <div className="page-not-found-text">
                        <h1>You are traveling on a bridge to nowhere</h1>
                        <h2>Please follow this link to make your way back <Link to="/"> home</Link></h2>
                    </div>
                </Page>

            </React.Fragment>
        );
    }

    // LIFE CYCLE   ===========================================
    // componentDidMount = () => {
    //     this.props.changeSiteBodyColor("page-not-found-bg");
    // }

}


