import React from 'react';
import SideBarItem from '../components/SideBarItem';
import Devider from '../../../a-h/devider/Devider';

const DefaultSideBarList = () => {
    return (
        <React.Fragment>
            <li><Devider /></li>
            <li><SideBarItem to="/site/about">About</SideBarItem></li>
            <li><SideBarItem to="/site/contact">Contact</SideBarItem></li>
            <li><Devider /></li>
            <li><SideBarItem to="/kindergarten/math/index">Kindergarten Math</SideBarItem></li>
            <ul style={{ listStyleType: "circle" }}>
                <li><SideBarItem to="/kindergarten/math/count-numbers/index">Count Numbers</SideBarItem></li>
                <li><SideBarItem to="/kindergarten/math/count-objects/index">Count Objects</SideBarItem></li>
                <li><SideBarItem to="/kindergarten/math/compare-objects/index">Compare Objects</SideBarItem></li>
                <li><SideBarItem to="/kindergarten/math/addition/index">Addition</SideBarItem></li>
                <li><SideBarItem to="/kindergarten/math/subtraction/index">Subtraction</SideBarItem></li>
                <li><SideBarItem to="/kindergarten/math/geometry/index">Geometry</SideBarItem></li>
            </ul>
            <li><SideBarItem to="/kindergarten/english/index">Kindergarten English</SideBarItem></li>
            <ul style={{ listStyleType: "circle" }}>
                <li><SideBarItem to="/kindergarten/english/reading/index">ABC</SideBarItem></li>
            </ul>
            <li><SideBarItem to="/web/index">Web Development</SideBarItem></li>
            <ul>
                <li><SideBarItem to="/web/javascript/index">Javascript</SideBarItem></li>
                <li><SideBarItem to="/web/tools/index">Tools</SideBarItem></li>
            </ul>

        </React.Fragment>
    );
}

export default DefaultSideBarList;


// <li><SideBarItem to="/web/react/hooks/index">React Hooks</SideBarItem></li>
