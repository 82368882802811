import { connect } from 'react-redux';
import PageNotFound from "./PageNotFound";
// import { changeSiteBodyColor } from 'util/store/site/siteColorActions';

const mapStateToProps = state => ({
    siteBgBodyColor: state.siteColorReducer.siteBgBodyColor,
    siteBgColor: state.siteColorReducer.siteBgColor,
});


// const mapDispatchToProps = dispatch => ({

//     changeSiteBodyColor: (color) => {
//         dispatch(changeSiteBodyColor(color));
//     },
// });

const Container = connect(
    mapStateToProps,
)(PageNotFound);

export default Container;