import React from "react";
import { HeaderText, HeaderH2, SmallHeaderContainer } from "./SiteHeader";
import PropTypes from 'prop-types';

const SmallHeader = (props) => (
    <SmallHeaderContainer className={props.className}>
        <HeaderText>
            <HeaderH2>{props.title}</HeaderH2>
        </HeaderText>
    </SmallHeaderContainer>
);

export default SmallHeader;


SmallHeader.propTypes = {
    title: PropTypes.string.isRequired
};