import React from 'react';
// import './styles/siteRibbon.css';
// import { FaWindowClose } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
// import closeButtonStyle from "./styles/closeButton";
import { Link } from 'react-router-dom';
// import SideBarItem from './SideBarItem';

const SidebarRibbon = ({ handdleToggleSidebar, shouldSideBarClose }) => {
    // console.log(handdleToggleSidebar)
    return (
        <div className="flexContainer flex-horizontal flex-middle flex-spaceBetween sidebar-ribbon color-black">
            <Link
                className="sidebar-site-name"
                to="/"
                onClick={shouldSideBarClose}
            >
                <span className="site-font font-md4">BellaZook</span>
            </Link>
            <span
                role="button"
                className="sidbar-close-btn"
                onClick={handdleToggleSidebar}
            >
                <IoMdClose className="font-lg" />
            </span>
        </div>
    );
}

export default SidebarRibbon;

// <div className="flexContainer flex-horizontal flex-middle flex-spaceBetween sidebar-ribbon color-black">
// <Button
//     className="sidbar-btn"
//     onClick={handdleToggleSidebar}
//     styles={{ ...btnClear }}

// >
//     <IoMdClose className="font-lg" />
// </Button>
// </div>
