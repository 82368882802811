import React from 'react';

export const SidebarContext = React.createContext();

export class SidebarProvider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeId: "",
            title: "This is it!"
        };
    }
    highlightSideBarItem = (lessonID) => {
        // console.log("hilight was invoced, id =", lessonID)
        this.setState(() => {
            return { activeId: lessonID }
        });
    }
    updateLessonTitle = (lessonTitle) => {
        // console.log("lesson title =", lessonTitle)
        this.setState(() => {
            return { title: lessonTitle }
        });
    }
    render() {
        return (
            <SidebarContext.Provider
                value={{
                    state: this.state,
                    highlightSideBarItem: this.highlightSideBarItem,
                    updateLessonTitle: this.updateLessonTitle
                }}
            >
                {this.props.children}
            </SidebarContext.Provider>
        );
    }
}
