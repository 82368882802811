import React from 'react';
import './styles/header.css';

export const HeaderContainer = (props) => (
    <div className="site-header bg-teal">
        {props.children}
    </div>
);

export const SmallHeaderContainer = (props) => (
    <div className={`site-header-small ${props.className}`}>
        {props.children}
    </div>

)
export const HeaderText = (props) => (
    <header className="site-header-text">
        {props.children}
    </header>

);
export const HeaderFooter = (props) => (
    <div className="site-header-footer">
        {props.children}
    </div>
);
export const HOneHTwoWrapper = (props) => (
    <h2 className="margin-50">
        {props.children}
    </h2>
);

export const HeaderH1 = (props) => (
    <h1 className="site-header-title font-effect-3d-float">
        {props.children}
    </h1>
);

export const HeaderH2 = (props) => (
    <h2 className="site-header-intro font-effect-3d-float">
        {props.children}
    </h2>
);
