import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
// import intervalManagerReducer from "./reducers/intervalManagerReducer";
// import playableReducer from './playable/reducer';
import siteColorReducer from "./site/siteColorReducer";
import navbarReducer from './navigation/reducer';
import lessonSpeedReducer from './lesson/reducer';
import lessonStartReducer from './lesson/start/reducer';

// import lessonRibbonReducer from '../../components/util/navbar/store/lessonRibbonReducer';
// import lessonRibbonReducer from '../../components/hoc/lessons/learn/store/lessonRibbonReducer';

export default (initialState) => {
    const store = createStore(
        combineReducers({
            siteColorReducer,
            navbarReducer,
            // playableReducer,
            lessonSpeedReducer,
            lessonStartReducer,
            // intervalManagerReducer
        }),
        initialState,

        //used for debuging in chrome
        compose(
            applyMiddleware(thunk),
            // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),//remove for build
        )
    );
    return store;
};

// export default compose(applyMiddleware(thunk))