import { START_LESSON, SHOW_CHARACTER } from './constants';

const defaultState = {
    start: false,
    characterIsVisible: true,
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case START_LESSON:
            return {
                ...state,
                start: action.payload
            }
        case SHOW_CHARACTER:
            // console.log("characterIsVisible ", action.payload)
            return {
                ...state,
                characterIsVisible: action.payload
            }

        default:
            return state;
    }
};