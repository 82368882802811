import { ADJUST_LESSON_SPEED, SET_DEFAULT_LESSON_SPEED, SHOW_SPEED_BUTTON } from './constants';
import { getLessonSpeed, calculateSpeedDisplayValue, checkIfDefaultSpeedEqualSpeed } from "./calculations";

const DEFAULT_DISPLAY_SPEED = 8;

const defaultState = {
    lessonSpeed: 1000,
    lessonSpeedDisplayed: DEFAULT_DISPLAY_SPEED,
    isDefault: true,
    hasSpeedButtons: false,
};

export default (state = defaultState, action) => {
    // console.log(state)
    switch (action.type) {
        case ADJUST_LESSON_SPEED:
            const calculatedLessonSpeed = getLessonSpeed(action.payload, state.lessonSpeed);
            const displaySpeed = calculateSpeedDisplayValue(calculatedLessonSpeed)
            return {
                ...state,
                lessonSpeed: calculatedLessonSpeed,
                lessonSpeedDisplayed: displaySpeed,
                isDefault: checkIfDefaultSpeedEqualSpeed(displaySpeed, state.isDefault, DEFAULT_DISPLAY_SPEED)
            };
        case SHOW_SPEED_BUTTON:
            // console.log("show in reducer ", action.payload)

            return {
                ...state,
                hasSpeedButtons: action.payload
            }
        case SET_DEFAULT_LESSON_SPEED:
            return state = defaultState
        default:
            return state;
    }
};

// export default siteColorReducer;
// return {
//     ...defaultState,
//     sidebarItems: action.payload
// };
// return Object.assign(
//     {},
//     ...state,
//     defaultState.navbarItems = action.payload
// );