import React from 'react';
import '../styles/sidebar.css';
import SidebarRibbon from '../components/SidebarRibbon';

const Sidebar = ({
    isSidebarOpen, handdleToggleSidebar, shouldSideBarClose,
    SidebarItems
}) => {
    return (
        <div style={isSidebarOpen && { width: "250px" }} className="sidebar" >
            <SidebarRibbon
                handdleToggleSidebar={handdleToggleSidebar}
                isSidebarOpen={isSidebarOpen}
                shouldSideBarClose={shouldSideBarClose}
            />
            <ul className="sidebar-items"
                onClick={shouldSideBarClose}
            >
                <SidebarItems />
            </ul>
        </div>
    );
}

export default Sidebar;