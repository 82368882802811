import React from 'react';
import { Link } from 'react-router-dom';
import './navlink.css';

const NavLink = (props) => {
    return (
        <Link className="navlink" to={props.to}>{props.children}</Link>
    );
}

export default NavLink;