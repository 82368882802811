import React from 'react';
import PlaceHolder from './PlaceHolder';
import NavigationBar from '../../navbar/index/Container';

const SiteConten = ({ isSidebarOpen, handdleToggleSidebar, children, navbarDynamicItems }) => {
    return (
        <div id="site-content" className={`${isSidebarOpen && 'siteContent-margin-left'}`}>
            <NavigationBar
                isSidebarOpen={isSidebarOpen}
                handdleToggleSidebar={handdleToggleSidebar}
                NavbarDynamicItems={navbarDynamicItems}
            />
            <PlaceHolder />
            {children}
        </div>

    );
}

export default SiteConten;

        //remove children div?
// flexContainer flex-vertical flex-center