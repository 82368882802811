const MAX_DISPLAY_VALUE = 9;
const MIN_SPEED = 8000;
const MAX_SPEED = 0;
const SECONDS = 1000;


export const checkIfDefaultSpeedEqualSpeed = (displaySpeed, isDefault, defaultSpeed) => {
    // console.log('check if deafault')
    if (displaySpeed !== defaultSpeed) {
        if (isDefault === true) {
            return false
        }
    } else {
        return true
    }
}
const calculateNextLessonSpeed = (incrementValue, lessonSpeedDisplayed) => {
    const modifyIncrement = incrementValue * SECONDS;
    const nextLessonSpeed = lessonSpeedDisplayed + modifyIncrement;
    return nextLessonSpeed;
}
export const calculateSpeedDisplayValue = (calculatedLessonSpeed) => {
    const nextDisplayValue = MAX_DISPLAY_VALUE - (calculatedLessonSpeed / 1000)
    return nextDisplayValue;
}

export const getLessonSpeed = (incrementValue, lessonSpeedDisplayed) => {
    // console.log(incrementValue, " - ", lessonSpeedDisplayed)
    switch (true) {
        case lessonSpeedDisplayed < MIN_SPEED && lessonSpeedDisplayed > MAX_SPEED:
            return calculateNextLessonSpeed(incrementValue, lessonSpeedDisplayed);
        case lessonSpeedDisplayed === MAX_SPEED && incrementValue === 1:
            return calculateNextLessonSpeed(incrementValue, lessonSpeedDisplayed);
        case lessonSpeedDisplayed === MIN_SPEED && incrementValue === -1:
            return calculateNextLessonSpeed(incrementValue, lessonSpeedDisplayed);
        default:
            return lessonSpeedDisplayed;
    }
}