import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import KindergartenEnglish from "views/grades/kindergarten/english/routes/index";
// import LandingPages from "../../views/landing-pages/routes/Index";
// import KindergartenMathCount from "../../../views/grades/kindergarten/math/count-numbers/routes/index";
import KindergartenMath from "views/grades/kindergarten/math/routes/index";
import SitePages from 'views/landing-pages/site/routes';
import WebTutorials from "views/web/routes";
import Home from '../../../views/landing-pages/home/route';
import PageNotFound from "../../../views/landing-pages/page-not-found/Container";
import SiteLayout from "../layout/site/Layout";
import ScrollToTop from "../scroll/ScrollToTop";

const AppRouter = (props) => (

    <BrowserRouter >
        <SiteLayout siteBgBodyColor={`${props.siteBgBodyColor}`}>
            <ScrollToTop>
                <Switch>
                    <Route path="/" component={Home} exact={true} />
                    <Route path="/site" component={SitePages} />
                    <Route path="/kindergarten/math" component={KindergartenMath} />
                    <Route path="/kindergarten/english" component={KindergartenEnglish} />
                    <Route path="/web" component={WebTutorials} />
                    <Route component={PageNotFound} />
                </Switch>
            </ScrollToTop>
        </SiteLayout>
    </BrowserRouter>
);

export default AppRouter;


// <Route path="/grade-six/english/:id" component={GradeSixEnglish} />
// <Route path="/kindergarten/english/:id" component={KindergartenEnglish} />
// <Route path="/tutorials/:id" component={Tutorials} />
// <Route path="/tools/:id" component={Tools} />
