import React from 'react';
import NavLink from '../../../../i-p/links/nav-link/NavLink';

const LandingPageNavItems = ({ isSidebarOpen }) => {
    // console.log(isSidebarOpen)

    return (
        <React.Fragment>
            {!isSidebarOpen &&
                <li><NavLink to="/">
                    <span className="site-font font-md4">BellaZook</span>
                </NavLink></li>}
            <li><NavLink to="/site/about">ABOUT</NavLink></li>
        </React.Fragment>
    );
}

export default LandingPageNavItems;