import { connect } from 'react-redux';
import SideBar from "./Sidebar";

const mapStateToProps = state => ({
    siteBgColor: state.siteColorReducer.siteBgColor,
    SidebarItems: state.navbarReducer.sidebarItems,
});

const SideBarContainer = connect(
    mapStateToProps,
)(SideBar);

export default SideBarContainer;
