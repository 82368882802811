import React from 'react';
import './styles/loader.css';

function Loading({ pastDelay }) {
    return pastDelay ? <div className="loader"></div> : null;
}

export default Loading;

// const Loading = () => <div className="loader"></div>;

// https://alligator.io/react/react-loadable/