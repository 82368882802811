import React from 'react';
import ExpandedFooter from "./ExpandedFooter";
import './styles/footer.css';

const Footer = ({ expandFooter, isSidebarOpen }) => (
    <footer className={`footer-main footer site-grid-footer ${isSidebarOpen && 'siteContent-margin-left'}`}>
        {expandFooter && ExpandedFooter}
        <p className="copyright">Copyright &copy; BellaZook 2017 to {new Date().getFullYear()}. All Rights Reserved</p>
    </footer>
);

export default Footer;