import React from 'react';

const BridgeToNowere = () => {
    return (
        <svg className="page-not-found-bg-image page-not-found-bg" viewBox="0 0 203.2 127" width="100%" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <linearGradient id="linearGradient1349">
                    <stop stopColor="#b3b3b3" id="stop1345" offset="0" />
                    <stop stopOpacity="0" stopColor="#b3b3b3" id="stop1347" offset="1" />
                </linearGradient>
                <linearGradient id="linearGradient1341">
                    <stop stopColor="gray" id="stop1337" offset="0" />
                    <stop stopOpacity="0" stopColor="gray" id="stop1339" offset="1" />
                </linearGradient>
                <linearGradient id="linearGradient1323">
                    <stop stopColor="#333" id="stop1319" offset="0" />
                    <stop stopColor="#b3b3b3" offset="0.8" id="stop1325" />
                    <stop stopOpacity="0" stopColor="#ccc" id="stop1321" offset="1" />
                </linearGradient>
                <linearGradient id="linearGradient1211">
                    <stop stopColor="#b3b3b3" id="stop1205" offset="0" />
                    <stop stopOpacity="0.7" stopColor="#c8c8c8" offset="0.4" id="stop1207" />
                    <stop stopOpacity="0" stopColor="#ccc" id="stop1209" offset="1" />
                </linearGradient>
                <linearGradient id="linearGradient868">
                    <stop stopColor="#4d4d4d" offset="0" id="stop864" />
                    <stop stopColor="gray" offset="1" id="stop866" />
                </linearGradient>
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870" x1="0" y1="0.49964" x2="1" y2="0.49964" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-3" x1="0" y1="0.49851" x2="1" y2="0.49851" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-2" x1="0" y1="0.49722" x2="1" y2="0.49722" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-9" x1="0" y1="0.60073" x2="0.96297" y2="0.60073" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-7" x1="0" y1="0.49951" x2="1" y2="0.49951" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-6" x1="0" y1="0.49857" x2="1" y2="0.49857" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-1" x1="0" y1="0.50056" x2="1" y2="0.50056" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-4" x1="0.0003" y1="0.50006" x2="0.97775" y2="0.50006" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5" x1="0.02964" y1="0.50504" x2="1.01284" y2="0.50504" />
                <linearGradient xlinkHref="#linearGradient1211" id="linearGradient1013" x1="-0.06056" y1="0.66829" x2="0.81475" y2="0.11873" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5-4" x1="-0.06105" y1="0.49759" x2="0.94564" y2="0.49759" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5-2" x1="-0.02751" y1="0.5007" x2="0.94081" y2="0.5007" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5-5" x1="0.04141" y1="0.50078" x2="1.00162" y2="0.50078" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5-1" x1="-0.00843" y1="0.49316" x2="0.93925" y2="0.49316" />
                <linearGradient xlinkHref="#linearGradient868" id="linearGradient870-5-0" x1="0.02493" y1="0.49836" x2="1.08272" y2="0.49836" />
                <linearGradient y2="0.05131" x2="0.60504" y1="1.01952" x1="0.60741" id="linearGradient1221" xlinkHref="#linearGradient1219" />
                <linearGradient id="linearGradient1219">
                    <stop stopOpacity="0" stopColor="#b3b3b3" id="stop1215" offset="0" />
                    <stop stopColor="#ccc" offset="0.3" id="stop1317" />
                    <stop stopColor="#ccc" offset="0.5" id="stop1223" />
                    <stop stopOpacity="0" stopColor="#b3b3b3" id="stop1217" offset="1" />
                </linearGradient>
                <linearGradient y2="0.49942" x2="0.91035" y1="0.49942" x1="-0.05252" id="linearGradient1313" xlinkHref="#linearGradient868" />
                <linearGradient y2="1.28941" x2="0.90511" y1="1.28941" x1="0.00848" id="linearGradient1315" xlinkHref="#linearGradient1323" />
                <linearGradient y2="0.27306" x2="0.08912" y1="0.39964" x1="0.89319" id="linearGradient1343" xlinkHref="#linearGradient1341" />
                <linearGradient x1="0" y2="0.5" x2="1" y1="0.5" id="linearGradient1351" xlinkHref="#linearGradient1349" />
            </defs>
            <g>
                <title>Bridge to nowhere</title>
                <g id="layer1">
                    <path strokeWidth="0.3" fill="url(#linearGradient1013)" d="m0,0.00001l0,127l133.4,0c-3,-21.4 17.5,-77.3 3.9,-102c-5.9,-10.8 -41.9,-17.2 -50.8,-25l-86.5,0z" id="path984" />
                </g>
                <g id="layer2">
                    <path d="m0,-0.2l203.2,0l0,127l-203.2,0l0,-127z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3" fill="url(#linearGradient1351)" opacity="0.7" id="rect1002" />
                    <path strokeWidth="0.4" fill="url(#linearGradient1343)" d="m56.3,16.2c-9,0 -17.8,5.3 -32.1,6.1c-9.3,0.6 -18.1,-1 -24.2,0.3l0,104.2l99.3,0c1,-7.2 2.4,-17.1 2.8,-25.7c0.2,-6.2 -4.7,-11.9 -4.6,-18.7c0.1,-6.7 -3.6,-13.5 -4,-21c-0.5,-13.9 5.2,-27.5 -2.1,-36.8c-2.5,-3.1 -18.8,-2 -22,-4.2a23,23 0 0 0 -13,-4.2l-0.1,0z" id="path1006" />
                </g>
                <g id="layer3">
                    <g id="g1056">
                        <g transform="matrix(1.1314,0,0,1.1314,-22.3,-45) " id="g1307">
                            <path d="m43.22491,81.423l0.7,0l0,25.6l-0.7,0l0,-25.6z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.2" fill="url(#linearGradient870-5-4)" id="rect862-2-5" />
                            <path d="m45.72491,81.723l0.5,0l0,21.9l-0.5,0l0,-21.9z" strokeLinejoin="round" strokeLinecap="round" fill="url(#linearGradient870-5-0)" id="rect862-2-2" />
                            <path d="m54.72491,84.523l1.3,0l0,11.2l-1.3,0l0,-11.2z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.1" fill="url(#linearGradient870-5-1)" id="rect862-2-17" />
                            <path d="m48.62491,83.023l1,0l0,16.6l-1,0l0,-16.6z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.1" fill="url(#linearGradient870-5-2)" id="rect862-2-1" />
                            <path d="m51.22491,83.723l1.1,0l0,13.3l-1.1,0l0,-13.3z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.1" fill="url(#linearGradient870-5-5)" id="rect862-2-59" />
                            <path d="m72.92491,91.423l2.6,0l0,61.2l-2.6,0l0,-61.2z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3.6" fill="url(#linearGradient870-1)" id="rect862-55" />
                            <path d="m58.32491,86.123l1.7,0l0,15.1l-1.7,0l0,-15.1z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="1.4" fill="url(#linearGradient870-5)" id="rect862-2" />
                            <path strokeLinejoin="round" strokeLinecap="round" strokeWidth="2.6" fill="url(#linearGradient870-9)" id="rect862-0" d="m153.02491,123.823l0,26.6l2.7,0l0,-26.7l-2.7,0.1z" />
                            <path d="m134.72491,115.823l2.6,0l0,35.2l-2.6,0l0,-35.2z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2.7" fill="url(#linearGradient870-2)" id="rect862-5" />
                            <path d="m117.12491,107.523l2.6,0l0,43.1l-2.6,0l0,-43.1z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3" fill="url(#linearGradient870-3)" id="rect862-7" />
                            <path d="m100.12491,100.823l2.6,0l0,49.7l-2.6,0l0,-49.7z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3.3" fill="url(#linearGradient870)" id="rect862" />
                            <path d="m89.92491,97.123l2.6,0l0,54.1l-2.6,0l0,-54.1z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3.4" fill="url(#linearGradient870-7)" id="rect862-73" />
                            <path d="m80.82491,93.423l2.6,0l0,57.5l-2.6,0l0,-57.5z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="3.5" fill="url(#linearGradient870-6)" id="rect862-8" />
                            <path d="m64.32491,90.323l2.1,0l0,47.2l-2.1,0l0,-47.2z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2.8" fill="url(#linearGradient1313)" id="rect862-86" transform="rotate(-0.699999988079071 -33.67509078979307,-148.57699584960974) " />
                            <path d="m61.92491,87.323l1.9,0l0,27.4l-1.9,0l0,-27.4z" strokeLinejoin="round" strokeLinecap="round" strokeWidth="2" fill="url(#linearGradient870-4)" id="rect862-58" />
                            <path strokeWidth="0.3" fill="url(#linearGradient1315)" d="m219.52491,161.723l-13,3.1c-49.2,-42.3 -119,-67.1 -168.2,-85c0,0 117.8,29.6 181.2,82l0,-0.1z" id="path832" />
                            <path strokeWidth="0.3" fill="#4d4d4d" d="m71.82491,153.023s-12.8,-55.4 -16.4,-57c-3.6,-1.4 -12.1,13.1 -12.1,13.1l-4.2,-26.6l4.7,23s9.7,-15.3 13,-10.7c3.5,4.5 18.2,58 18.2,58l-3.2,0.2z" id="path840" />
                        </g>
                    </g>
                </g>
                <g id="layer4">
                    <path strokeWidth="0.3" fill="url(#linearGradient1221)" id="path1213" d="m-3.3,126.1c-0.7,-10.5 4.5,-25.5 14.3,-32.3c5.3,-3.7 11.8,-5 19.5,-2c22.2,8.9 39,37.5 60.4,25.1c15,-8.7 33,-11.8 47.3,-6.1c14.3,5.7 26.7,3.9 45.4,13c11,5.5 20.9,12.6 16.6,20.2c-2.7,4.8 -9.1,-4.4 -16.3,-2.4c-21.1,5.7 -14.5,-10.5 -24.7,-4.2c-11.4,7 -41.4,23 -61.4,9.8s-35.1,-7.4 -45.6,-7c-10.4,0.4 -22,4.8 -30.8,5.7c-8.6,0.9 -24,-9.1 -24.7,-19.8z" />
                </g>
            </g>
        </svg>
    );
}

export default BridgeToNowere;