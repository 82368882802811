import { connect } from 'react-redux';
import AppRouter from "./AppRouter";

const mapStateToProps = state => ({
    siteBgBodyColor: state.siteColorReducer.siteBgBodyColor,
});

const RouterContainer = connect(
    mapStateToProps,
)(AppRouter);


export default RouterContainer;