const defaultState = {
    siteBgColor: 'bg-teal',
    siteBgBodyColor: 'bg-lightGray'
};

export default (state = defaultState, action) => {
    // console.log(action.type)

    switch (action.type) {
        case 'CHANGE_SITE_COLOR':
            // console.log(action.payload)
            return {
                ...state,
                siteBgColor: action.payload
            };
        case 'CHANGE_SITE_BODY_COLOR':
            // console.log("bg ", action.payload)
            return {
                ...state,
                siteBgBodyColor: action.payload
            }
        case 'CHANGE_ALL_SITE_COLOR':
            return {
                siteBgBodyColor: action.payload,
                siteBgColor: action.payload
            }
        case 'SET_DEFAULT_COLOR':
            return state = defaultState

        default:
            return state;
    }
};

// export default siteColorReducer;