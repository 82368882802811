// import SiteNavItems from "components/navbar/nav-items/SiteNavItems";
import SiteNavItems from "../../../components/util/navbar/nav-items/landing-pages";
import SidebarItems from "../../../components/util/sidebar/landing-pages/index";

import {
    SET_NAV_ITEMS, SET_SIDE_NAV_ITEMS, SET_DEFAULT_NAV,
    SET_PREVIOUS_PAGE, SET_NEXT_PAGE, CREATE_PAGINATION_LIST
} from './constant';

const defaultState = {
    navbarItems: SiteNavItems,
    sidebarItems: SidebarItems,
    paginationList: [],
    previousPage: "/",
    nextPage: "/"
};

export default (state = defaultState, action) => {
    // console.log(action.payload)
    switch (action.type) {

        case SET_NAV_ITEMS:
            return {
                ...state,
                navbarItems: action.payload
            };
        case CREATE_PAGINATION_LIST:
            // console.log(action.payload)
            return {
                ...state,
                paginationList: action.payload
            };

        case SET_SIDE_NAV_ITEMS:
            return {
                ...state,
                sidebarItems: action.payload
            };

        case SET_PREVIOUS_PAGE:
            return {
                ...state,
                previousPage: action.payload,
            };
        case SET_NEXT_PAGE:
            return {
                ...state,
                nextPage: action.payload
            };

        case SET_DEFAULT_NAV:
            return state = defaultState
        default:
            return state;
    }
};

// export default siteColorReducer;
// return {
//     ...defaultState,
//     sidebarItems: action.payload
// };
// return Object.assign(
//     {},
//     ...state,
//     defaultState.navbarItems = action.payload
// );