import React from "react";
import { Link } from 'react-router-dom';
// import { FaTwitter, FaYoutube } from 'react-icons/fa';
// import { FaTwitter, FaYoutube } from 'react-icons/fa';
// FaPaperPlane
// import ResponsiveContainer from "../container/ResponsiveContainer";

const ExpandedFooter = () => (
    <div>
        <ul className="list-inline">
            <li className='list-inline-item'><Link to='/'>Home</Link></li>
            <li className='list-inline-item'><Link to='/home/about'>About</Link></li>
            <li className='list-inline-item'><Link to='/home/contact'>Contact</Link></li>
            <li className='list-inline-item'>
            </li>
        </ul>
    </div>
);

export default ExpandedFooter;


// <a id="btnYouTube"
// className="btn "
// href="https://www.youtube.com/channel/UCVN7eHjd63p769Re3aEpuHQ"
// target="_blank" rel="noopener noreferrer"
// >
// <FaYoutube id="quizIcon" className="color-red icon-md" aria-hidden="true" />
// </a>
// <a
// className="btn btn-clear"
// href="https://twitter.com/ZookBella"
// target="_blank" rel="noopener noreferrer"
// >
// <FaTwitter
//     className="color-white font-lg" aria-hidden="true"
// />
// </a>
