import { connect } from 'react-redux';
import NavigationBar from "./NavigationBar";

const mapStateToProps = state => ({
    siteBgColor: state.siteColorReducer.siteBgColor,
    NavbarItems: state.navbarReducer.navbarItems,
});

const NavBarContainer = connect(
    mapStateToProps,
)(NavigationBar);

export default NavBarContainer;
