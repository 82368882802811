import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppRouter from './components/util/route/Container';
import configureStore from './util/store/configureStore';
import './styles/normalizer.css';
import './styles/index.css'

// NOT FOR PRODUCTION
// coment out before build
// window.React = React; react-addons-perf
//=====================

const store = configureStore();

const bellaApp = (
    <Provider store={store}>
        <AppRouter />
    </Provider>
);

ReactDOM.render(bellaApp, document.getElementById('root'));