import React from 'react';
import '../styles/navbar.css';
import Button from '../../../a-h/button/Button';
import { IoMdMenu } from "react-icons/io";
import { btnClear } from '../../../a-h/button/styles/buttonStyles';


const NavigationBar = ({ handdleToggleSidebar, isSidebarOpen, siteBgColor, NavbarItems }) => {
    // console.log(siteBgColor)
    return (
        <div className={`flexContainer flex-horizontal flex-middle flex-spaceBetween siteRibbon 
        ${siteBgColor} 
        ${isSidebarOpen && 'navbar-width-calc'}`}
        >
            <div className="flexContainer flex-horizontal flex-middle" >
                {!isSidebarOpen &&
                    <Button
                        styles={{ ...btnClear }}
                        onClick={handdleToggleSidebar}
                    >
                        <IoMdMenu className="font-lg color-white" />
                    </Button>
                }
                <ul className="navbar-items flexContainer flex-horizontal flex-middle">
                    <NavbarItems isSidebarOpen={isSidebarOpen} />
                </ul>
            </div>
        </div>
    );
}

export default NavigationBar;

// <span>Donate</span>
// <li style="float:right"><a class="active" href="#about">About</a></li>


// const enhancedStyle = {
//     visibility: isSidebarOpen ? "hidden" : "visible"
// }
// , ...enhancedStyle

// ${!isSidebarOpen ? 'siteRibbon-width' : 'siteRibbon-width-calc'}